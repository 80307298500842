/*font path*/
$fa-font-path: "../webfonts";

// Plugins

// FontAwesome 5
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";

// Header Styles
.read-only, .logged-in-as {
  margin: 0 !important;
  border-radius: 0 !important;
}

@import '_custom.scss';